import React, { Component } from "react"

import Layout from "../../components/layout"
import GatedContent from "../../components/gatedcontent"

import imgputtyauth from "../../images/guide/puttyauth.png"
import imgputtysession from "../../images/guide/puttysession.png"

export default class SshAwsServerIndex extends Component {
	render() {
		return (
			<Layout showtitle={true} showtitleback={true} activePath='guide' fullPath='guide/ssh-to-aws-server' pageTitle="SSH to an AWS Server" privatePage={true}>
				<GatedContent>
					<div className={"margin-content"}>
						Open SSH terminal to AWS EC2 Instance.  Kindly request for the necessary credentials from your respective administrator/supervisor.
						<h2>Usage</h2>
						Once configured, just run the corresponding commands/tool, according to your operating system.
						<h3>Windows (Putty)</h3>
						<ol>
							<li>Open Putty</li>
							<li>Select Session</li>
							<li>Click Load</li>
							<li>Click Open</li>
						</ol>
						<h3>Linux/Mac</h3>
						Run the following from your terminal
						<div className={"copybox-textbox"}>
							ssh -i &lt;Path to Key file&gt; &lt;User Name&gt;@&lt;Server URL&gt;
						</div>
						<ul>
							Example:<br/>
							ssh -i ~/keys/myserver.pem testuser@some.server.com
						</ul>

						<h2>Configuration</h2>
						One-time configurations necessary to start connecting to the server.
						<h3>Windows (Putty)</h3>
						<ol>
							<li>Download and install appropriate <a rel="noopener noreferrer" href='https://www.chiark.greenend.org.uk/~sgtatham/putty/latest.html' target='_blank'>Putty</a> version</li>
							<li>Open Putty</li>
							<li>From the left-hand-side panel, select "Connection&nbsp;&gt;&nbsp;SSH&nbsp;&gt;&nbsp;Auth"</li>
							<img alt="" src={imgputtyauth} />
							<li>Click Browse, open the PPK file provided</li>
							<li>From the left-hand-side panel, select "Connection"
								<ul>
									<li><strong>Host Name</strong>: provided</li>
									<li><strong>Port</strong>: 22</li>
									<li>Select <span class='iamnet-copyline'>SSH</span></li>
									<li><strong>Saved Sessions</strong>: Set preferred name</li>
									<li>Click "Save"</li>
									<img alt="" src={imgputtysession} />
									<li>Click Open</li>
									<li>Click Yes when asked</li>
								</ul>
							</li>
						</ol>
						<h3>Mac / Linux</h3>
						<ol>
							<li>Store the PEM file in your preferred location</li>
							<li>Change permission of the PEM file to 600</li>
							<div className={"copybox-textbox"}>
								chmod 600 &lt;path to PEM file&gt;
							</div>
							<ul>
								Example:<br/>
								chmod 600 ~/keys/myserver.pem
							</ul>
						</ol>
						
					</div>
				</GatedContent>
			</Layout>
		)
	}
}



